import { defineComponent } from 'vue'

import { Download as DownloadIcon } from '@element-plus/icons'

import DsView from '@/components/DsView'
import { Model, Reference } from '@/components/DsForm'
import { Column } from '@/components/DsTable'
import DsTag from '@/components/DsTag'
import { availableProductColors, availableProductHeel } from '@/assets/enums'

import { getPdf } from '@/components/PdfViewer'
declare global {
  interface Crypto {
    randomUUID: () => string
  }
}

const recipeModel: Model = {
  type: 'object',
  indent: false,
  properties: {
    master_recipe: { type: 'string', label: 'Recipe Group', required: true },
    product: { type: 'string', label: 'Recipe Name', required: true },
    owner: {
      type: 'uuid',
      model: 'users',
      label: 'Default Owner',
      optionLabel: (user: any) => `${user.name} ${user.lastname}`,
      required: true,
    },
    workshop: {
      type: 'string',
      label: 'Workshop',
      required: true,
    },
    order_id: {
      type: 'uuid',
      model: 'orders',
      label: 'Order',
      disabled: true,
    },
    description: {
      type: 'string',
      label: 'Description',
    },
    size: {
      type: 'string',
      label: 'Size',
    },
    heel: {
      type: 'string',
      enum: availableProductHeel,
      allowCreate: true,
      label: 'Heel',
    },
    color: {
      type: 'string',
      enum: availableProductColors,
      label: 'Color',
    },
    picture: {
      type: 'file',
      label: 'Picture',
    },
    comment: {
      type: 'string',
      label: 'Comment',
      disabled: true,
    },
    steps: {
      type: 'array',
      propertiesLayout: 'section',
      label: 'Steps',
      itemKeyName: 'step_name',
      items: {
        type: 'object',
        label: 'Step',
        properties: {
          step_name: { type: 'string', label: 'Step Name', required: true },
          owner: {
            type: 'uuid',
            model: 'users',
            label: 'Default Owner',
            required: true,
            optionLabel: (user: any) => `${user.name} ${user.lastname}`,
          },
          description: { type: 'string', label: 'Description' },
          comment: { type: 'string', label: 'Comment', disabled: true },
          depend_on: {
            type: 'string',
            label: 'Depend on',
            enum: [
              {
                value: 1,
                label: 'Write the name(s) of the previous step(s)',
                disabled: true,
              },
            ],
            multiple: true,
            allowCreate: true,
          },
          components: {
            type: 'array',
            itemKeyName: 'alias',
            items: {
              type: 'object',
              label: 'Component',
              properties: {
                alias: {
                  type: 'string',
                  label: 'Component Alias',
                },
                component_id: {
                  type: 'uuid',
                  model: 'components',
                  label: 'Component',
                  required: true,
                },
                quantity: {
                  type: 'number',
                  label: 'Quantity',
                  required: true,
                  suffix: new Reference(
                    'local',
                    'component_id',
                    'components',
                    'unit'
                  ),
                },
              },
            },
            label: 'Components',
          },
          pictures: {
            type: 'file',
            label: 'Picture',
          },
        },
      },
    },
  },
}

const columns: Column[] = [
  { key: 'product', header: 'Name' },
  {
    key: 'owner',
    header: 'Default owner',
    formatter: (value: any, relations: any) => {
      if (value === undefined || !relations.users || !relations.users[value])
        return '-'
      else {
        return (
          <DsTag>
            {relations.users[value].lastname +
              ' ' +
              relations.users[value].name}
          </DsTag>
        )
      }
    },
  },
  // {
  //   key: 'picture',
  //   header: 'Picture',
  //   formatter: (value: any, relations: any) => {
  //     console.log(value, relations)
  //     return '📎'
  //   },
  // },
  {
    key: 'steps',
    header: 'Number of steps',
    formatter: (value) => {
      if (Array.isArray(value)) {
        return String(value.length)
      }
      return '•'
    },
  },
]

const defaultOrder = (): any => ({
  id: crypto.randomUUID(),
  product: '',
  owner: '',
  order_id: null,
  steps: [],
})

export default defineComponent({
  name: 'Recipes',
  components: { DsView },
  setup() {
    return () => (
      <div>
        <DsView
          view={{
            collection: 'recipes',
            title: 'Recipes',
            model: recipeModel,
            columns: columns,
            headerKey: 'product',
            createButtonLabel: 'New recipe',
            rowKey: 'id',
            history: false,
            groupByKey: 'master_recipe',
            mainAction: {
              icon: DownloadIcon,
              label: 'Bolla PDF',
              callback: async (value) => getPdf(value),
            },
          }}
          backendDefaultFilter={{
            type: 'group',
            condition: 'AND',
            filters: [
              {
                type: 'filter',
                property: 'order_id',
                operator: 'IS_EMPTY',
                value: '',
              },
            ],
          }}
          defaultValue={defaultOrder}
        />
      </div>
    )
  },
})
